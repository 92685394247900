///////////////////
// COLOR PALETTE //
///////////////////

$color-white: #fff;
$color-green: #11be64;
$color-gray-dark: #515c6f;
$color-gray-dark-1: #707070;
$color-gray-dark-2: #55565a;
$color-gray-light-1: #8b90a3;
$color-gray-light-2: #cecfd0;

$color-primary: #1baafc;
$color-primary-light: rgba(5, 161, 252, 0.1);

// Typography
$color-text-white: $color-white;
$color-text-dark: $color-gray-dark;
$color-text-light: $color-gray-light-1;
$color-text-primary: $color-primary;

// Backgrounds
$color-background-default: $color-white;
$color-background-primary: $color-primary;
$color-background-light: $color-primary-light;

// Scrollbar
$color-scrollbar-track: $color-white;
$color-scrollbar-thumb: $color-primary;
$color-scrollbar-thumb-hover: $color-primary-light;
