@import 'src/assets/scss/resources';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  // background-color: $color-white;
}

input {
  &::-ms-reveal {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }
}

.view_mobile {
  @include media(lg) {
    display: none;
  }
}

.view_desktop {
  display: none;

  @include media(lg) {
    display: inherit;
  }
}