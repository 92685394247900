@import 'palette';

@font-face {
  font-family: 'Helvetica-Bold';
  src: url('~assets/fonts/Helvetica-Bold.ttf');
}
@font-face {
  font-family: 'Helvetica';
  src: url('~assets/fonts/Helvetica.ttf');
}
// @font-face {
//   font-family: 'Roboto-Regular';
//   src: url('~assets/fonts/Roboto-Regular.ttf');
// }
// @font-face {
//   font-family: 'Roboto-Bold';
//   src: url('~assets/fonts/Roboto-Bold.ttf');
// }
@font-face {
  font-family: 'SFProDisplay';
  src: url('~assets/fonts/SFProDisplay.otf');
}
@font-face {
  font-family: 'YouRe-Gone';
  src: url('~assets/fonts/YouRe-Gone.ttf');
}
@font-face {
  font-family: 'MyriadHebrew-Regular';
  src: url('~assets/fonts/Myriad-Hebrew-Regular.otf');
}

// Text size settings

@mixin text-size($size) {
  @if $size == xs {
    font-size: 8px;
  }
  @if $size == sm {
    font-size: 12px;
  }
  @if $size == md {
    font-size: 14px;
  }
  @if $size == lg {
    font-size: 16px;
  }
  @if $size == xl {
    font-size: 18px;
  }
  @if $size == xxl {
    font-size: 20px;
  }
}

// Headers [h1, h2...]

@mixin heading($type) {
  color: $color-text-dark;
  font-weight: 700;
  font-family: 'Helvetica-Bold', sans-serif;

  @if $type == 'default' {
    @include text-size(xxl);
  }
  @if $type == 'wide' {
    @include text-size(xxl);

    letter-spacing: 2px;
  }
  @if $type == 'small' {
    @include text-size(xl);
  }
  @if $type == 'extra-small' {
    @include text-size(lg);
  }
}

// Text styles [p, a...]

@mixin text($type) {
  font-weight: 400;
  font-family: 'Helvetica', sans-serif;

  @if $type == 'default' {
    @include text-size(sm);

    color: $color-text-light;
  }
  @if $type == 'large' {
    @include text-size(sm);

    letter-spacing: 0.7px;
    line-height: 19px;
    color: $color-text-light;

    @include media(md) {
      @include text-size(md);
    }
  }
  @if $type == 'wide' {
    @include text-size(xs);

    letter-spacing: 1.2px;
    color: $color-text-light;

    // @include media(lg) {
    //   @include text-size(sm);
    // }
  }
  @if $type == 'light' {
    @include text-size(md);

    color: $color-text-white;
  }
  @if $type == 'primary' {
    @include text-size(sm);

    color: $color-text-primary;
  }
}
